import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Nav from "../components/nav"
import Img from "gatsby-image"
import Grunge1 from "../images/purple-grunge-bg.inline.svg"
import Grunge2 from "../images/yellow-grunge-bg.inline.svg"
import { Link } from "gatsby"

class IndexPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    const { data } = this.props

    return (
      <Layout black={true}>
        <SEO title="Kingdom Kids | Logan" />
        <Nav />
        <header className="kdk-home-header kdk-top-section">
          <div className="container kdk-home-header-content">
            <div className="kdk-home-header__icon"></div>
            <h1 className="kdk-home-header__text">
              Every child <span className="first">awakened to</span> and{" "}
              <span className="second">transformed by</span> the Father's heart.
            </h1>
          </div>
        </header>
        <section className="kdk-home-image-1">
          <Img fluid={data.headerImage2.childImageSharp.fluid} />
        </section>
        <section className="kdk-home-quote">
          <div className="container">
            <div className="grunge-1">
              <Grunge1 />
            </div>
            <div className="grunge-2">
              <Grunge2 />
            </div>
            <div className="kdk-home-quote__content">
              <h2>
                see what great love the Father has given us, that we should be
                called God's children - and we are!
              </h2>
              <p>1 John 3:1-2</p>
            </div>
          </div>
        </section>
        <section className="kdk-home-mission">
          <div className="container">
            <p>Our Mission</p>
            <h2>
              Creating a space of love, acceptance and safety where children can
              learn about God as their heavenly Father. As they experience His
              transforming love, they will carry it into their families,
              friendships and the next generation.
            </h2>
            <Link className="btn-default" to="/support">
              Support Us
            </Link>
          </div>
        </section>
        <section className="kdk-home-parks">
          <div className="container">
            <h2>Join us</h2>
            {/* <p>
              Join us at a local park between 4pm and 6pm for an afternoon of
              games, love, Jesus and food!
            </p> */}
          </div>
          <div className="kdk-home-parks-content">
            <div className="columns">
              <div className="column">
                <h3>wednesdays</h3>
                <p>
                  4pm - 6pm
                  <br />
                  Ewing Park (Train Park)
                </p>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://goo.gl/maps/3QkFKWAVaV9kAj4P9"
                  className="btn-default"
                >
                  Get directions
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="kdk-home-support">
          <div className="container">
            <div className="logo-glitch"></div>
            <h2>Ways to support</h2>
            <div className="columns">
              <div className="column is-one-third">
                <h3>1. Pray</h3>
                <p>
                  Please pray that God would save many kids from the kingdom of
                  darkness so that they can live with Jesus in the kingdom of
                  light.
                </p>
                <a
                  href="mailto:info@kingdomkids.org.au"
                  className="btn-default"
                >
                  Get Requests
                </a>
              </div>
              <div className="column is-one-third">
                <h3>2. Donate</h3>
                <p>
                  Would you consider supporting us financially to reach more
                  kids with the love of God? Our team members volunteer their
                  time and effort for this cause so that 100% of donations can
                  go directly to this ministry.
                </p>
                <Link className="btn-default" to="/support">
                  Support Us
                </Link>
              </div>
              <div className="column is-one-third">
                <h3>3. Join</h3>
                <p>
                  Do you have a heart for kids and a passion to see Logan come
                  to Jesus? Please reach out to us and enquire about becoming a
                  volunteer or leader.
                </p>
                <a
                  href="mailto:info@kingdomkids.org.au"
                  className="btn-default"
                >
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    headerImage1: file(relativePath: { eq: "kdk-header-image.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    headerImage2: file(relativePath: { eq: "header-image-final.png" }) {
      childImageSharp {
        fluid(traceSVG: { color: "#fff100" }) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
